import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { PointPackage } from "../_models";
import { appConfig } from "../app.config";

@Injectable()
export class PointPackageService {
  constructor(private http: HttpClient) {}

  getAll(page: number, sortFields: string, ordering: number) {
    return this.http.get<PointPackage[]>(
      appConfig.apiUrl +
        "/point_package/" +
        page +
        "/" +
        sortFields +
        "/" +
        ordering
    );
  }

  getAllBySearchKey(
    page: number,
    sortFields: string,
    ordering: number,
    searchkey: any
  ) {
    return this.http.get<PointPackage[]>(
      appConfig.apiUrl +
        "/point_package/search/" +
        page +
        "/" +
        sortFields +
        "/" +
        ordering +
        "/" +
        searchkey
    );
  }

  getById(id: string) {
    return this.http.get(appConfig.apiUrl + "/point_package/edit/" + id);
  }

  create(PointPackage: PointPackage) {
    return this.http.post(appConfig.apiUrl + "/point_package", PointPackage);
  }

  update(PointPackage: PointPackage) {
    return this.http.put(
      appConfig.apiUrl + "/point_package/" + PointPackage.id,
      PointPackage
    );
  }

  delete(id: string) {
    return this.http.delete(appConfig.apiUrl + "/point_package/" + id);
  }

  updateStatus(id: string, status: string) {
    return this.http.put(appConfig.apiUrl + "/point_package/status/" + id, {
      status: status,
    });
  }
}
