import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeleteAccountRequest } from '../_models';
import { appConfig } from '../app.config';

@Injectable()
export class DeleteAccountRequestService {
    constructor(private http: HttpClient) { }

    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<DeleteAccountRequest[]>(appConfig.apiUrl+'/deleteAccountRequest/'+page+'/'+sortFields+'/'+ordering);        
    }
    getAllBySearchKey(page : number,sortFields : string, ordering : number,searchkey:any) {
        
        return this.http.get<DeleteAccountRequest[]>(appConfig.apiUrl+'/deleteAccountRequest/search/'+page+'/'+sortFields+'/'+ordering+'/'+searchkey);        
    }
    delete(id: string) {
        return this.http.delete(appConfig.apiUrl+'/deleteAccountRequest/' + id);
    }
    getById(id: string) {
        return this.http.get(appConfig.apiUrl+'/deleteAccountRequest/current/' + id);
    }
}