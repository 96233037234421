﻿import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
        if (currentUser && currentUser.result.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.result.token}`
                }
            });
        }

        return next.handle(request)
            .catch(err => {
                // onError
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        localStorage.clear();
                        //window.location.href = "http://node.newmediaguru.co/peershot-dev/dist/#/login";
                        window.location.href = "https://app.peershots.com/#/login";
                    }
                }
                return Observable.throw(err);
            }) as any;
    }
}