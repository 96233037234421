import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-message",
  templateUrl: "message.component.html",
  styles: [
    `
      .invalid-feedback {
        display: block;
      }
    `,
  ],
})
export class MessageComponent implements OnInit {
  message = "";
  status = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.message = params.msg;
      this.status = params.status;
    });
    console.log(this.message);
    console.log(this.status);
  }
}
