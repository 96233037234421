﻿import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { ManageReportedPhotos } from '../_models';
import { appConfig } from '../app.config';
import 'rxjs/Rx';
import{observable} from 'rxjs';

@Injectable()
export class ManageReportedPhotosService {
    constructor(private http: HttpClient) { }

    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<ManageReportedPhotos[]>(appConfig.apiUrl+'/manage_reported_photos/'+page+'/'+sortFields+'/'+ordering);        
    }
    getAllBySearchKey(page : number,sortFields : string, ordering : number,startdt:any,enddt:any) {
        
        return this.http.get<ManageReportedPhotos[]>(appConfig.apiUrl+'/manage_reported_photos/search/'+page+'/'+sortFields+'/'+ordering+'/'+startdt+'/'+enddt);        
    }

    getById(id: string) {
        return this.http.get(appConfig.apiUrl+'/manage_reported_photos/current/' + id);
    }

    getUserIdByRoleId(id: string) {
        return this.http.get(appConfig.apiUrl+'/manage_reported_photos/role/' + id);
    }

    create(user: ManageReportedPhotos) {
        return this.http.post(appConfig.apiUrl+'/manage_reported_photos', user);
    }

    update(user: ManageReportedPhotos) {
        return this.http.put(appConfig.apiUrl+'/manage_reported_photos/' + user.id, user);
    }  
   
    changeStatus(id: string, status:number) {
       return this.http.put(appConfig.apiUrl+'/manage_reported_photos/status/' +id, {status:status});
    }
    getCurrentMonthData(currentmonth:any){
        return this.http.get(appConfig.apiUrl+'/manage_reported_photos/currentmonth/' + currentmonth);

    }
    download(id: string,filename:string) {
        return this.http.put(appConfig.apiUrl+'/photo/download/' +id, {status:1},
            {responseType:'blob',
            headers:new HttpHeaders().append('Content-Type','application/json')
        
        });

        
    }
    delete(id: string) {
        return this.http.delete(appConfig.apiUrl+'/manage_reported_photos/' + id);
    }
}