import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AlertService, AuthenticationService } from "../../_services/index";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import "rxjs/add/operator/map";

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
  styles: [
    `
      .invalid-feedback {
        display: block;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  message: string;
  loginForm: FormGroup;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    // Form validation
    this.loginForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(6),
          Validators.maxLength(60),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
    });
    if (localStorage.getItem("currentUser")) {
      this.router.navigate(["dashboard"]);
    }
  }

  //Login
  login() {
    this.loading = true;
    this.message = "";
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (data) => {
          if (data.code == 200) {
            this.router.navigate(["dashboard"]);
          } else {
            this.alertService.error(data.message);
          }
          this.loading = false;
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
