﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ManageTransaction } from '../_models';
import { appConfig } from '../app.config';

@Injectable()
export class ManageTransactionService {
    constructor(private http: HttpClient) { }

    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<ManageTransaction[]>(appConfig.apiUrl+'/manage_transaction/'+page+'/'+sortFields+'/'+ordering);        
    }
    getAllBySearchKey(page : number,sortFields : string, ordering : number,searchkey:any,startdt:any,enddt:any) {
        
        return this.http.get<ManageTransaction[]>(appConfig.apiUrl+'/manage_transaction/search/'+page+'/'+sortFields+'/'+ordering+'/'+searchkey+'/'+startdt+'/'+enddt);        
    }

    getById(id: string) {
        return this.http.get(appConfig.apiUrl+'/manage_transaction/current/' + id);
    }

    getUserIdByRoleId(id: string) {
        return this.http.get(appConfig.apiUrl+'/manage_transaction/role/' + id);
    }

    create(user: ManageTransaction) {
        return this.http.post(appConfig.apiUrl+'/manage_transaction', user);
    }

    update(user: ManageTransaction) {
        return this.http.put(appConfig.apiUrl+'/manage_transaction/' + user.id, user);
    }  
   
    changeStatus(id: string, status:number) {
       return this.http.put(appConfig.apiUrl+'/manage_transaction/status/' +id, {status:status});
    }
    getCurrentMonthData(currentmonth:any){
        return this.http.get(appConfig.apiUrl+'/manage_transaction/currentmonth/' + currentmonth);

    }
    totalRevenue(){
        return this.http.get(appConfig.apiUrl+'/manage_transaction/totalRevenue');
    }
}