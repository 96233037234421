﻿import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { PhotoPurchase } from '../_models';
import { appConfig } from '../app.config';
import 'rxjs/Rx';
import{observable} from 'rxjs';

@Injectable()
export class PhotoPurchaseService {
    constructor(private http: HttpClient) { }

    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<PhotoPurchase[]>(appConfig.apiUrl+'/photo/purchased-photo/'+page+'/'+sortFields+'/'+ordering);        
    }
    getAllBySearchKey(page : number,sortFields : string, ordering : number,startdt:any,enddt:any) {
        
        return this.http.get<PhotoPurchase[]>(appConfig.apiUrl+'/photo/purchased-photo/search/'+page+'/'+sortFields+'/'+ordering+'/'+startdt+'/'+enddt);        
    }
    download(id: string,filename:string) {
        return this.http.put(appConfig.apiUrl+'/photo/download/' +id, {status:1},
            {responseType:'blob',
            headers:new HttpHeaders().append('Content-Type','application/json')
        
        });

        
     }
    
}