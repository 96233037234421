import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule, Injectable } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent } from "./containers";

import { AlertComponent } from "./_directives/index";
import { AuthGuard } from "./_guards/index";
import { JwtInterceptor } from "./_helpers/index";
import {
  AlertService,
  AuthenticationService,
  UserService,
  CmsService,
  RolesService,
  ValidationService,
  EmailTemplateService,
  GlobalSettingService,
  PointPackageService,
  ManagePriceService,
  ProductService,
  ChangePasswordService,
  AppUserService,
  PhotoService,
  ContactAdminService,
  RedemptionRequestService,
  ManageTransactionService,
  LeaderboardService,
  FeedbackService,
  ManageReportedPhotosService,
  PhotoPurchaseService,
  FaqService,
  DeleteAccountService,
  DeleteAccountRequestService,
} from "./_services/index";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { ForgetPasswordComponent } from "./views/forgetpassword/forgetpassword.component";
import { ResetPasswordComponent } from "./views/resetpassword/resetpassword.component";
import { RegisterComponent } from "./views/register/register.component";
import { TinymceModule } from "angular2-tinymce";
import { MessageComponent } from "./views/message/message.component";

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts/ng2-charts";

@Injectable()
export class ErrorLogService {
  private name: String = "ErrorLogService";

  logError(error: any) {
    if (error instanceof HttpErrorResponse) {
      console.error(
        "There was an HTTP error.",
        error.message,
        "Status code:",
        (<HttpErrorResponse>error).status
      );
    } else if (error instanceof TypeError) {
      console.error("There was a Type error.", error.message);
    } else if (error instanceof Error) {
      console.error("There was a general error.", error.message);
    } else {
      console.error("Nobody threw an error but something happened!", error);
    }
  }
}

// global error handler that utilizes the above created service (ideally in its own file)

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private errorLogService: ErrorLogService) {
    super();
  }

  handleError(error) {
    this.errorLogService.logError(error);
  }
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    TinymceModule.withConfig({}),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    AlertComponent,
    RegisterComponent,
    AlertComponent,
    MessageComponent,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    ValidationService,
    UserService,
    CmsService,
    EmailTemplateService,
    GlobalSettingService,
    PointPackageService,
    ManagePriceService,
    ProductService,
    RolesService,
    ChangePasswordService,
    AppUserService,
    RedemptionRequestService,
    PhotoService,
    ContactAdminService,
    ManageTransactionService,
    FeedbackService,
    LeaderboardService,
    ManageReportedPhotosService,
    PhotoPurchaseService,
    FaqService,
    DeleteAccountService,
    DeleteAccountRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // register global error handler
    GlobalErrorHandler,
    // register global error log service
    ErrorLogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
