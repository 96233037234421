import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Photo } from '../_models/index';
import { appConfig } from '../app.config';
import 'rxjs/Rx';
import{observable} from 'rxjs';

@Injectable()
export class PhotoService {
    constructor(private http: HttpClient) { }   

    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<Photo[]>(appConfig.apiUrl+'/photo/'+page+'/'+sortFields+'/'+ordering);        
    }
    getAllBySearchKey(page : number,sortFields : string, ordering : number,searchkey:any) {
        
        return this.http.get<Photo[]>(appConfig.apiUrl+'/photo/search/'+page+'/'+sortFields+'/'+ordering+'/'+searchkey);        
    }
    delete(id: string) {
        return this.http.delete(appConfig.apiUrl+'/photo/' + id);
    }
    status(id: string, status:number) {
       return this.http.put(appConfig.apiUrl+'/photo/status/' +id, {status:status});
    }
    download(id: string,filename:string) {
        return this.http.put(appConfig.apiUrl+'/photo/download/' +id, {status:1},
            {responseType:'blob',
            headers:new HttpHeaders().append('Content-Type','application/json')
        
        });
        
    }
    getById(id: string) {
        return this.http.get(appConfig.apiUrl+'/photo/current/' + id);
    }    
    publicfeedStatus(id: string, isPublicPhoto:number) { 
        return this.http.put(appConfig.apiUrl+'/photo/showhidepublic/' +id, {isPublicPhoto:isPublicPhoto});
     }
     multipledlete(allids: string[]) {
        return this.http.post(appConfig.apiUrl+'/photo/multipledelete',allids);
    }
     
    
}