import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styles: [` 
  .invalid-feedback{
    display: block;
  }
  .img-pointer{
    cursor:pointer
  } 
  animated fadeIn{
    z-index: -1;
  }
  `
]
})
export class AppComponent implements OnInit {
  
  constructor(private router: Router,
      private route :ActivatedRoute
    ) { }

  ngOnInit() {
    

    // Navidate to login components
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
