﻿import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AlertService } from '../_services/index';

@Component({
    moduleId: module.id,
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnDestroy {
    private subscription: Subscription;
    message: any;
    timmer : any;

    constructor(private alertService: AlertService) { 

        // subscribe to alert messages
        this.subscription = alertService.getMessage().subscribe(message => { 
            this.message = message; 
        });
    }

    removeAlert(alert) {
        this.message = null;
    }


    ngOnDestroy(): void {
        // unsubscribe on destroy to prevent memory leaks
        this.subscription.unsubscribe();
               
    }
}