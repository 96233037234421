﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeleteAccount } from '../_models/index';
import { appConfig } from '../app.config';

@Injectable()
export class DeleteAccountService {
    constructor(private http: HttpClient) { }
   
    getAll(page : number,sortFields : string, ordering : number) {
        
        return this.http.get<DeleteAccount[]>(appConfig.apiUrl+'/deleteAccount/'+page+'/'+sortFields+'/'+ordering);        
    }

    getAllBySearchKey(page : number,sortFields : string, ordering : number,searchkey:any) {
        
        return this.http.get<DeleteAccount[]>(appConfig.apiUrl+'/deleteAccount/search/'+page+'/'+sortFields+'/'+ordering+'/'+searchkey);        
    }

    getById(id: string) {
        
        return this.http.get(appConfig.apiUrl+'/deleteAccount/edit/' + id);
    }

    create(deleteAccount: DeleteAccount) {
        return this.http.post(appConfig.apiUrl+'/deleteAccount', deleteAccount);
    }

    update(deleteAccount: DeleteAccount) {
        return this.http.put(appConfig.apiUrl+'/deleteAccount/' + deleteAccount.id, deleteAccount);
    }

    updateStatus(id: string, status:string) {
     
    return this.http.put(appConfig.apiUrl+'/deleteAccount/status/' +id, {status:status});
    }

    delete(id: string) {
        return this.http.delete(appConfig.apiUrl+'/deleteAccount/' + id);
    }
}